import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"



const IndexPage = () => (
  <Layout>
    <SEO title="LOST-IT" keywords={[`LOST-IT`, `obsługa informatyczna firm`, `serwis`, `rodo`]} />

    <Link to="wiecej" >
        <span class="aaa_dot"></span><span class="aaa_dot"></span><span class="aaa_dot3"></span>
    </Link>

   

 <p>W związku z zapisami Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016 dotyczącymi ochrony danych osobowych , zwanymi RODO obowiązującymi od 25 maja 2018 informujemy, że Administratorem Danych Osobowych (dalej „Administrator”) jest LOST-IT Artur Losa z siedzibą w Rudzie Śląskiej przy ul. Cypriana Norwida 19 lok 12, 41-700 Ruda Śląska. Podanie danych jest dobrowolne, jednak ich udostępnienie jest niezbędne do załatwienia wszelkich formalności z jakimi Państwo zwracają się do Administratora. Podane dane osobowe mogą być udostępniane przez Administratora podmiotom z nim współpracującym (odbiorcom) w celu załatwienia tychże formalności. Państwa dane osobowe przechowywane będą przez czas niezbędny do obsłużenia Państwa sprawy lub przez czas niezbędny do wywiązania się przez Administratora z obowiązku prawnego.<br/>
Mają Państwo prawo dostępu do treści swoich danych, prawo do ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo wniesienia sprzeciwu wobec przetwarzania oraz prawo do przenoszenia danych. Przysługuje również prawo wniesienia skargi do organu nadzorczego (Prezes Urzędu Ochrony Danych Osobowych w Warszawie), jeśli uznają Państwo, że przetwarzanie dane osobowe naruszają przepisy o ochronie danych osobowych.</p>

  </Layout>
)

export default IndexPage
